<template>
    <div class="container mt-5 mb-6">
        <div class="row">
            <div class="col-12 col-md-12">

                <div class="card text-center mb-5 animate__animated animate__fadeIn" >
                    <div class="card-header d-flex justify-content-start h5 text-white" style="background-color: #444444;">CS Report - 11-21-2021</div>
                    <div class="card-header">
                        <div class="accordion" role="tablist">
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <div v-b-toggle.client_info>
                                        <span class="when-closed">
                                            <span class="float-left"><strong class="mr-1">Client:</strong>Name <strong class="mr-1">Plan Type:</strong>Type</span>
                                            <i class="fa fa-arrow-down d-flex justify-content-end mt-1 mr-2" />
                                        </span>
                                        <span class="when-open">
                                            <span class="float-left mr-2"><strong class="">Client Information</strong></span>
                                            <i class="fa fa-arrow-up d-flex justify-content-end mt-1 mr-2" />
                                        </span>
                                    </div>
                                </b-card-header>

                                <b-collapse id="client_info" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div class="row">
                                            <div class="col-3"><strong class="mr-1">Client:</strong> Name</div>
                                            <div class="col-3"><strong class="mr-1">Plan Type:</strong> Type</div>
                                            <div class="col-3"><strong class="mr-1">Search Type:</strong> Type</div>
                                            <div class="col-3"><strong class="mr-1">Run Time:</strong>Time</div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <div v-b-toggle.widget_info>
                                        <span class="when-closed">
                                            <span class="float-left"><strong class="mr-1"> 3 Widgets</strong></span>
                                            <i class="fa fa-arrow-down d-flex justify-content-end mt-1 mr-2" />
                                        </span>
                                        <span class="when-open">
                                            <span class="float-left mr-2"><strong class="">Widget Information</strong></span>
                                            <i class="fa fa-arrow-up d-flex justify-content-end mt-1 mr-2" />
                                        </span>
                                    </div>
                                </b-card-header>

                                <b-collapse id="widget_info" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div class="row">
                                            <div class="col-3"><strong class="mr-1">Client:</strong> Name</div>
                                            <div class="col-3"><strong class="mr-1">Plan Type:</strong> Type</div>
                                            <div class="col-3"><strong class="mr-1">Search Type:</strong> Type</div>
                                            <div class="col-3"><strong class="mr-1">Run Time:</strong>Time</div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>

                    </div>
                    <div class="card-body p-0">
                        <div class="container-fluid p-0">
                            <div class="mt-3 ml-5 d-flex justify-content-start"><h4>Work Item Summary</h4></div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <ejs-accumulationchart id="matches" :tooltip="tooltip" :pointClick="click"  :legendSettings="legendSettings">
                                            <e-accumulation-series-collection>
                                                <e-accumulation-series :dataSource='seriesData' tooltipMappingName='text' xName='x' yName='y' radius="150" :dataLabel='datalabel' legendShape='Rectangle' :pointColorMapping='pointColorMapping'> </e-accumulation-series>
                                            </e-accumulation-series-collection>
                                        </ejs-accumulationchart>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <ejs-accumulationchart id="searches" :tooltip="tooltip" :pointClick="click" :legendSettings="legendSettings">
                                            <e-accumulation-series-collection>
                                                <e-accumulation-series :dataSource='searchData' tooltipMappingName='text' xName='x' yName='y' radius="150" :dataLabel='datalabel' legendShape='Rectangle' :pointColorMapping='pointColorMapping'> </e-accumulation-series>
                                            </e-accumulation-series-collection>
                                        </ejs-accumulationchart>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0" style="background-color: #6f7171; height: 23px"></div>
                            <div class="mt-3 ml-5 d-flex justify-content-start"><h4>Summary by Widgets</h4></div>
                            <div class="row">

                            </div>
                            <div class="row m-0" style="background-color: #6f7171; height: 23px"></div>
                            <div class="mt-3 ml-5 d-flex justify-content-start"><h4>Summary by Automated Actions</h4></div>
                            <div class="row">

                            </div>
                            <div class="row m-0" style="background-color: #6f7171; height: 23px"></div>
                            <div class="mt-3 ml-5 d-flex justify-content-start"><h4>Matched Image Information</h4></div>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div v-if="!view" class="ml-5 mr-5 mb-3 mt-2 border contain" style="height: 304px !important; overflow-y: scroll;">
                                        <img v-for="image in matchedImages" :key="image.id" class="image-placeholder m-3" @click="imageDetails(image)" :src="image.url"  />
                                    </div>
                                    <div v-if="view" class="ml-5 mr-5 mb-3 mt-2 border contain" style="height: 304px;">
                                        <div class="container">
                                            <div class="row d-flex justify-content-end p-2"><i class="fa fa-times" @click="view = false" style="cursor: pointer;" /></div>
                                            <div class="row">
                                                <div class="col-4 col-md-4">
                                                    <img class="img-fluid m-3" :src="imgDetails.url"  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0" style="background-color: #6f7171; height: 23px"></div>
                            <div class="mt-3 ml-5 d-flex justify-content-start"><h4>Site Link Data</h4></div>
                            <div class="row">

                            </div>
                            <div class="row m-0" style="background-color: #6f7171; height: 23px"></div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { AccumulationChartPlugin, PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationLegend } from "@syncfusion/ej2-vue-charts";

Vue.use(AccumulationChartPlugin);

export default {
    name: "ContentShieldReport",
    data() {
        return {
            view: false,
            imgDetails: '',
            collapse: {
                id: '',
                shown: ''
            },
            seriesData: [{ x: 'Fail', y: 340, fill: '#498fff', text:'Failures' }, { x: 'Pass', y: 530, fill: '#ffa060', text: 'Passes' }],
            searchData: [{ x: 'Mar', y: 70, fill: '#ff68b6', text: 'Scanned' }, { x: 'Apr', y: 15, fill: '#81e2a1', text: 'Not Scanned' }],
            tooltip: {enable: true, format: '${point.text} : <b>${point.y}%</b>'},
            matchedImages: [
                {id: 1, url: '/image_placeholder.png'},
                {id: 2, url: '/image_placeholder.png'},
                {id: 3, url: '/image_placeholder.png'},
                {id: 4, url: '/image_placeholder.png'},
                {id: 5, url: '/image_placeholder.png'},
                {id: 6, url: '/image_placeholder.png'},
            ],
            legendSettings:{ position:'Top' },
            datalabel: { visible: true, name: 'y' },
            pointColorMapping: 'fill'
        }
    },
    provide: {
        accumulationchart: [PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationLegend]
    },
    created() {
      this.$root.preloader = false
    },
    mounted() {

    },
    methods: {
        imageDetails(obj) {
            this.imgDetails = obj
            this.view = true
        },
        click(obj) {
            alert( `${obj.point.label} was clicked` )
        }
    }
}
</script>

<style scoped>
    .contain::-webkit-scrollbar
    {
        background-color: white;
        border-radius: 10px;
        width: 20px;
    }
    
    .contain::-webkit-scrollbar-thumb
    {
        background-color: grey;
        border-radius: 10px;
        border: 5px solid white;
    }
    
    .contain::-webkit-scrollbar-thumb:vertical
    {
        height: 20px!important;
        width: 20px;
    }

    .image-placeholder {
        width: 200px;
        height: 120px;
        cursor: pointer;
    }

    .image-placeholder:hover {
        box-shadow: 1px 5px 10px rgba(0,0,0,0.7);
        transition: box-shadow 0.3s ease-in-out;
    }

    .collapsed > .when-open,
    .not-collapsed > .when-closed {
        display: none;
    }

</style>
